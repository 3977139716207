import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { AccountHelpSubpage } from './AccountHelpSubpage';
import { App } from './App';
import { Button, ButtonVariant } from './Button';
import { CartSku } from './CartSku';
import { Container } from './Container';
import { CustomerForm } from './forms/CustomerForm';
import { NewsletterForm } from './forms/NewsletterForm';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { LoadingOverlay } from './LoadingOverlay';
import { OffcanvasHeader } from './Offcanvas';
import { ThemeColor } from './pages/TemplatePage';
import { PriceBlock } from './PriceBlock';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { reportWebVitals } from './reportWebVitals';
import { ServiceContactsSubpage } from './ServiceContactsSubpage';

b2x.init<ThemeColor, ButtonVariant, IconName, IconSize>({
  api: {
    apiKey: 'GHGDEFDAABGIHCHHIGJBEI',
    productPopulate: {
      alternativeImages: true,
      attributeGroups: true,
      attributes: true,
      brand: true,
      breadcrumbs: true,
      content: true,
      offers: true,
      priceRange: true,
      reviews: true,
      skus: {
        attributes: true,
        price: true,
      },
      summaryReviews: true,
      variantProducts: {
        skus: true,
      },
    },
    productTilePopulate: {
      attributes: true,
      brand: true,
      offers: true,
      priceRange: true,
      skus: {
        attributes: true,
        price: true,
      },
      summaryReviews: true,
      variantProducts: {
        skus: true,
      },
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              giftCard: true,
              sku: {
                attributes: true,
                product: {
                  attributes: true,
                },
              },
            },
          },
          shippingProfile: true,
        },
        customer: { qualifiers: true },
        wishlist: { products: true, skus: { product: true } },
      },
    },
  },
  defaultButtonVariant: 'primary',
  enableBestPrice: true,
  enableProductAvailabilityEmailNotification: true,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-primary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  i18n: {
    en: en,
    it: it,
  },
  icons: {
    arrow: {
      left: 'small-arrow-left',
    },
    cart: 'cart',
    cartCampaign: 'gift',
    close: 'delete',
    configurator: 'filter',
    download: 'small-arrow-down',
    info: 'info',
    logout: 'exit',
    loyalty: 'programma-fedelta',
    passwordToggle: {
      hidden: 'hidden',
      visible: 'visible',
    },
  },

  installmentPaymentBanner: {
    // paypal:
    //   b2x.environment === 'PRODUCTION'
    //     ? {
    //         enabled: true,
    //         layout: 'text',
    //         text: { color: 'black', logo: 'inline' },
    //       }
    //     : undefined,
    scalapay: {
      enabled: true,
    },
  },

  // paypal:
  //   b2x.environment === 'PRODUCTION'
  //     ? {
  //         merchantClientId: 'AS-zz8zfHI-6-jFIsCMZd2J5R_jO4U9qywhakSJWGiDVuFhlWwgB1UKv1lRSdg0k25CXwt481-mbbp1y',
  //       }
  //     : undefined,
  preRelease: false,

  preselectFirstAvailableSku: true,
  preselectPreferredSku: true,
  productMaxQuantity: 130,
  projectName: 'purina',
  ui: 'bs5',
  variants: {
    AccountHelpSubpage: AccountHelpSubpage,
    AccountQuickReorderSubpage: 'A',
    AccountReferralSubpage: 'A',
    AddressFieldset: 'A',
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartSku: CartSku,
    ChangePasswordForm: 'A',
    Container: Container,
    CustomerAddressForm: 'A',
    // RegistrationForm: RegistrationCustomerForm,
    CustomerForm: CustomerForm,
    GiftCardForm: 'A',
    InvoiceFieldset: 'A',
    LoadingOverlay: LoadingOverlay,
    LocaleForm: 'A',
    LoginForm: 'A',
    NewsletterForm: NewsletterForm,
    OffcanvasHeader: OffcanvasHeader,
    OrderDetails: 'A',
    OrderGuestForm: 'A',
    // PersonalDataForm: PersonalDataForm,
    PriceBlock: PriceBlock,
    ProductReviews: 'A',
    ProductStickyFooter: 'B',
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    ReviewFormModal: 'A',
    ReviewsActions: 'A',
    SearchFilters: 'B',
    ServiceContactsSubpage: ServiceContactsSubpage,
    SimpleSearchForm: 'A',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
